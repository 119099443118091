import { useTranslation } from 'react-i18next';
import { UserContext } from '../../contexts/UserContext';
import { useContext, useEffect } from 'react';
import styles from './OrderPage.module.scss';
import { useParams } from 'react-router-dom';
import Button from '../../components/Button/Button';
import { formatDate } from '../../utils/formatDate';
import OrderLineItems from '../../components/OrderLineItems/OrderLineItems';
import { getOrderLineItemsSelectedIds } from '../../utils/getOrderLineItemsSelectedIds';
import Loading from '../../components/Loading/Loading';
import Content from '../../components/Content/Content';
import CancelOrderModal from '../../components/CancelOrderModal/CancelOrderModal';
import { ModalsContext } from '../../contexts/ModalsContext';
import EditOrderModal from '../../components/EditOrderModal/EditOrderModal';
import BackButton from '../../components/BackButton/BackButton';
import OrderStages from '../../components/OrderStages/OrderStages';
import Card from '../../components/Card/Card';
import CardLine from '../../components/CardLine/CardLine';
import TableData from '../../components/TableData/TableData';
import OrderFiles from '../../components/OrderFiles/OrderFiles';
import { formatPrice } from '../../utils/formatPrice';

export default function OrderPage() {
   const { t } = useTranslation('orders');

   let { id } = useParams();

   const { deals, loadingDeals, fetchOrderLineItems, lineItems } =
      useContext(UserContext);

   const { open } = useContext(ModalsContext);

   useEffect(() => {
      if (!id) return;

      fetchOrderLineItems(id);
   }, [id]);

   const order = deals?.orderDeals.find((order) => order.id.toString() === id);

   const openCancelOrderModal = () => {
      open(<CancelOrderModal />);
   };

   const openEditOrderModal = () => {
      open(<EditOrderModal />);
   };

   if (loadingDeals) {
      return (
         <Content>
            <Loading large />
         </Content>
      );
   }

   if (!id || !order) {
      return (
         <Content>
            <div>{t('order_not_found')}</div>
         </Content>
      );
   }

   const orderLineItems =
      lineItems && id ? getOrderLineItemsSelectedIds(lineItems[id]) : [];

   return (
      <Content>
         <BackButton to='/orders'>{t('order_page')}</BackButton>

         <h1 className={styles.title}>
            {t('order_number')} {order.orderDetails.orderNumber}
         </h1>

         <div className={styles.header}>
            <div className={styles.expectedDelivery}>
               <div>{t('expected_delivery')}</div>
               <strong>
                  {order.deliveryDetails.expectedDelivery
                     ? order.deliveryDetails.expectedDelivery
                     : 'N/A'}
               </strong>
            </div>

            <div className={styles.actions}>
               <Button
                  disabled={order.orderDetails.currentStage !== 'Open Orders'}
                  target='blank'
                  variant='ghost'
                  onClick={openCancelOrderModal}
               >
                  {t('cancel_order')}
               </Button>

               <Button
                  disabled={order.orderDetails.currentStage !== 'Open Orders'}
                  target='blank'
                  variant='primary'
                  onClick={openEditOrderModal}
               >
                  {t('edit_specifications')}
               </Button>
            </div>
         </div>

         <div className={styles.status}>{t('status')}:</div>
         <OrderStages stages={order.orderStageHistoryOrderedAscending} />

         <div className={styles.details}>
            <Card title={t('delivery_details')}>
               <CardLine>
                  <TableData>
                     <tr>
                        <td>{t('delivery_method')}</td>
                        <td>{order.deliveryDetails.deliveryMethod}</td>
                     </tr>
                     <tr>
                        <td>{t('delivery_address')}</td>
                        <td>
                           {order.deliveryDetails.deliveryStreetName || 'N/A'}
                           {', '}
                           {order.deliveryDetails.deliveryPostalCode}{' '}
                           {order.deliveryDetails.deliveryCity || 'N/A'}
                           {', '}
                           {order.deliveryDetails.deliveryCountryIsoCode ||
                              'N/A'}
                        </td>
                     </tr>
                     <tr>
                        <td>{t('delivery_name')}</td>
                        <td>{order.deliveryDetails.name || 'N/A'}</td>
                     </tr>
                     <tr>
                        <td>{t('delivery_phone')}</td>
                        <td>{order.deliveryDetails.phoneNumber || 'N/A'}</td>
                     </tr>
                     <tr>
                        <td>{t('delivery_email')}</td>
                        <td>{order.deliveryDetails.email || 'N/A'}</td>
                     </tr>
                  </TableData>
               </CardLine>
            </Card>

            <Card title={t('order_details')}>
               <CardLine>
                  <TableData>
                     <tr>
                        <td>{t('order_number')}</td>
                        <td>{order.orderDetails.orderNumber}</td>
                     </tr>
                     <tr>
                        <td>{t('order_sent')}</td>
                        <td>
                           {formatDate(order.orderDetails.orderSent, true) ||
                              'N/A'}
                        </td>
                     </tr>
                     <tr>
                        <td>{t('discount_code_used')}</td>
                        <td>{order.orderDetails.discountCodeUsed || 'N/A'}</td>
                     </tr>
                     <tr>
                        <td>{t('payment_method')}</td>
                        <td>{order.orderDetails.paymentMethod || 'N/A'}</td>
                     </tr>
                     <tr>
                        <td>{t('price')}</td>
                        <td>
                           {formatPrice(
                              order.orderDetails.totalPrice,
                              order.orderDetails.currencyCode
                           )}
                        </td>
                     </tr>
                  </TableData>
               </CardLine>
            </Card>
         </div>

         <OrderLineItems
            lineItems={orderLineItems}
            currency={order.orderDetails.currencyCode}
         />

         <OrderFiles />
      </Content>
   );
}
