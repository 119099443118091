import { useTranslation } from 'react-i18next';
import Content from '../../components/Content/Content';
import { useContext } from 'react';
import { UserContext } from '../../contexts/UserContext';
import Loading from '../../components/Loading/Loading';
import Card from '../../components/Card/Card';
import ProposalLine from '../../components/ProposalLine/ProposalLine';
import { ReactComponent as ProposalIcon } from '../../assets/icons/proposal.svg';
import CardLine from '../../components/CardLine/CardLine';
import Button from '../../components/Button/Button';
import useSiteConfig from '../../hooks/useSiteConfig';

export default function ProposalsPage() {
   const { t } = useTranslation('proposals');
   const { deals, loadingDeals } = useContext(UserContext);

   const proposals = deals?.proposalDeals;

   const { config } = useSiteConfig();

   return (
      <Content>
         <h1>{t('title')}</h1>

         {loadingDeals ? (
            <Loading />
         ) : (
            <Card title={t('proposals')} icon={<ProposalIcon />}>
               {proposals && proposals.length > 0 ? (
                  proposals.map((proposal) => (
                     <ProposalLine key={proposal.id} proposal={proposal} />
                  ))
               ) : (
                  <CardLine>{!loadingDeals && t('no_proposals')}</CardLine>
               )}
            </Card>
         )}

         <Button to={`mailto:${config?.email}`}>{t('get_proposal')}</Button>
      </Content>
   );
}
